<template>
  <v-card>
    <!-- toolbar -->
    <v-toolbar flat>
      <v-toolbar-title>{{ name }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selected.length > 0"
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        @click="deleteMultipleItems"
        >{{ $t('operations.delete') }} ({{ selected.length }})
      </v-btn>
      <v-btn
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        @click="exportItems"
        >{{ $t('operations.export') }}
      </v-btn>
      <v-btn
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        :loading="isSelecting"
        @click="importItems"
        >{{ $t('operations.import') }}
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept=".xlsx"
          @change="onFileChanged"
        />
      </v-btn>
      <v-btn color="teal dark" dark class="mx-1" @click="newItem">
        {{ $t('operations.new') }}
      </v-btn>
    </v-toolbar>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        autocomplete="off"
      >
        <template v-slot:label>{{ $t('operations.search') }} </template>
      </v-text-field>
    </v-card-title>
    <!-- table -->
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :single-select="false"
      :search="search"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: $t('operations.items_per_page')
      }"
      sort-by="id"
      show-select
    >
      <template v-slot:loading>{{ $t('operations.loading') }} </template>
      <template v-slot:[`item.options`]="{ item }">
        <div>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey lighten-2 dark"
            class="mx-1"
            dark
            small
            @click="editItem(item)"
            >{{ $t('operations.edit') }}
          </v-btn>

          <v-btn
            color="blue-grey lighten-2 dark"
            class="mx-1"
            dark
            small
            @click="deleteItem(item)"
            >{{ $t('operations.delete') }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <!-- form -->
    <AppDialogForm ref="dialogForm" :isValid="isValid">
      <v-form ref="form" lazy-validation v-model="isValid">
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="item.code"
              :rules="formRules"
              autocomplete="off"
              required
              autofocus
            >
              <template v-slot:label>{{ $t('fields.code') }} </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="item.name"
              :rules="formRules"
              autocomplete="off"
              required
            >
              <template v-slot:label>{{ $t('fields.name') }} </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field v-model="item.description" autocomplete="off">
              <template v-slot:label>{{ $t('fields.description') }} </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </AppDialogForm>
    <!-- confirm -->
    <AppDialogConfirm ref="confirm" />
  </v-card>
</template>

<script>
import AppDialogConfirm from '@/components/dialogs/Confirm.vue'
import AppDialogForm from '@/components/dialogs/Form.vue'
export default {
  name: 'Causes',
  components: { AppDialogConfirm, AppDialogForm },
  data: () => ({
    name: null,
    url: null,
    url_file: null,
    headers: [],
    formRules: [(v) => !!v || 'Required'],
    items: [],
    item: {},
    selected: [],
    loading: false,
    loading_text: null,
    isSelecting: false,
    search: null,
    isValid: false
  }),

  created() {
    this.url = 'system/document_types/'
    this.url_file = 'system/document_types/file/'
    this.name = this.$t('options.system.document_types')
    this.headers = [
      { text: 'Id', value: 'id' },
      { text: this.$t('fields.code'), value: 'code' },
      { text: this.$t('fields.name'), value: 'name' },
      { text: this.$t('fields.description'), value: 'description' },
      { text: this.$t('fields.options'), value: 'options', align: 'right' }
    ]
    this.getItems()
  },

  methods: {
    getItems() {
      this.loading = true
      this.$http.get(this.url).then((request) => {
        this.loading = false
        this.items = request.data
      })
    },

    newItem() {
      let item = { code: '', name: '', description: '' }
      this.item = Object.assign({}, item)
      let options = { color: 'teal' }
      let title = this.$t('operations.new').toUpperCase()
      this.$refs.dialogForm.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate())
          this.$http
            .post(this.url, JSON.stringify(this.item))
            .then(() => this.getItems())
      })
    },

    editItem(item) {
      this.item = Object.assign({}, item)
      let options = { color: 'teal' }
      let title = `${this.$t('operations.editing').toUpperCase()} [${item.id}]`
      this.$refs.dialogForm.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate())
          this.$http
            .put(`${this.url}?id=${this.item.id}`, JSON.stringify(this.item))
            .then(() => this.getItems())
      })
    },

    deleteItem(item) {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let messaje = this.$t('dialogs.confirm_text')
      this.$refs.confirm.open(title, messaje, options).then((confirm) => {
        if (confirm)
          this.$http
            .delete(`${this.url}?id=${item.id}`)
            .then(() => this.getItems())
      })
    },

    async deleteMultipleItems() {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let messaje = this.$t('dialogs.confirm_text')
      let confirm = await this.$refs.confirm.open(title, messaje, options)
      if (await confirm) {
        var processed = 0
        this.selected.forEach(async (item) => {
          await this.$http.delete(`${this.url}?id=${item.id}`).then(() => {
            processed++
            if (processed === this.selected.length) {
              this.getItems()
            }
          })
        })
      }
    },

    exportItems() {
      this.$http
        .get(this.url_file, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${this.name}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    importItems() {
      this.isSelecting = true
      window.addEventListener('focus', () => (this.isSelecting = false), {
        once: true
      })
      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      const data = new FormData()
      data.append('file', e.target.files[0])
      this.$http
        .post(this.url_file, data)
        .then(() => {
          this.getItems()
          this.$refs.uploader.value = null
        })
        .catch(() => (this.$refs.uploader.value = null))
    }
  }
}
</script>

<style></style>
