<template>
  <AppDialog ref="dialog">
    <v-card>
      <v-list>
        <v-list-item-group multiple>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @change="change(item)"
          >
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox v-model="item.is_active"></v-checkbox>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </AppDialog>
</template>

<script>
import AppDialog from '@/components/dialogs/FullScreen.vue'
export default {
  name: 'FormalityRequeriments',
  components: { AppDialog },
  data: () => ({ url: null, items: [] }),

  created() {
    this.url = 'system/formality/requeriments/'
  },

  methods: {
    open(item) {
      let options = { color: 'teal' }
      let title = String(item.name).toUpperCase()
      this.item = item
      this.$http.get(`${this.url}?formality_id=${item.id}`).then((response) => {
        this.items = response.data
        this.$refs.dialog.open(title, options).then(() => {})
      })
    },

    change(item) {
      item.is_active = !item.is_active
      item.requeriment_type_id = item.id
      item.formality_id = this.item.id
      this.$http.post(this.url, JSON.stringify(item))
    }
  }
}
</script>

<style>
</style>